header1 {
	background-color: var(--primary-bg-color);
	display: flex; 
	justify-content: space-between;
	align-items: center; 
	background-image: url("../../../assets/images/event.svg");
	background-size: cover;
}

.header-icon {
	cursor: pointer;
	font-size: 1.1rem;
	margin-right: 1rem;
	margin-left: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	vertical-align: middle;
}

.header-image {
	border-radius: 50%;
	height: 2rem;
	margin-right: 1rem;
	margin-left: 1rem;
	max-width: 2rem;
	vertical-align: middle;
}

.header-left {
	display: flex;
	justify-content: center;
    align-items: center;
}

.header-buttons {
	display: flex;
	align-items: center;
}

.header-buttons div {
	border: 0.1rem var(--secundary) solid;
	padding: 0.1rem 1rem 0.1rem 1rem;
 
	margin-left: .5rem;
	margin-right: .5rem; 
	color: var(--secundary);
}

.header-right {
	display: flex;
	justify-content: center;
	align-items: center;
}