.interestList {
	display: flex;
    flex-wrap: wrap;
    background-color: var(--superlight);
    border-radius: .5rem;
}

.interestList-item {
    margin: .5rem;
    border: .1rem var(--border-color) solid;
    border-radius: 1rem;
    padding: .2rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.interestList-item-selected{
    color: var(--superlight);
    background-color: var(--border-color);
    margin: .5rem;
    border: .1rem var(--border-color) solid;
    border-radius: 1rem;
    padding: .2rem;
    padding-left: .5rem;
    padding-right: .5rem;
}