html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
section,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
div,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-weight: inherit;
	font-style: inherit;
	font-size: 100%;
	font-family: inherit;
	vertical-align: baseline;
}

:focus {
	outline: 0;
}

a:link { text-decoration: none; }

a:visited { text-decoration: none; }

a:hover { text-decoration: none; }

a:active { text-decoration: none; }

*, *::before, *::after {
	box-sizing: border-box;
  }
  * {
	margin: 0;
  }
  html, body {
	height: 100%;
  }
  body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
  }
  img, picture, video, canvas, svg {
	display: block;
	max-width: 100%;
  }
  input, button, textarea, select {
	font: inherit;
  }
  p, h1, h2, h3, h4, h5, h6 {
	overflow-wrap: break-word;
  }
  #root, #__next {
	isolation: isolate;
  }

@font-face {
	font-family: "acme";
	src: local("acme"),
		url("./assets/fonts/Acme-Regular.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "oswald";
	src: local("oswald"),
		url("./assets/fonts/Oswald-VariableFont_wght.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "patuaone";
	src: local("patuaone"),
		url("./assets/fonts/PatuaOne-Regular.ttf") format("truetype");
	font-weight: bold;
}



@font-face {
	font-family: "montserrat-m";
	src: local("nunitosb"),
		url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "montserrat-sb";
	src: local("nunito"),
		url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "montserrat-eb";
	src: local("nunito"),
		url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
	font-weight: bold;
}

body {
	margin: 0;
	font-family: "arial", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}