.breadcrumb {     
    align-items: center;
    display: flex;
}

.breadcrumb > div{
    color: var(--secundary);
    background-color: var(--primary-bg-color);
    font-weight: bold;
    padding: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1rem;
}