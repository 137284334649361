@import "./assets/styles/variables.css";

body {
	background-color: var(--second-bg-color);
}

a{
	color:var(--dark);
}

.cmontent {
	display: flex;
	background-color: var(--second-bg-color);
}

.content > div:last-child {
	flex-grow: 1;
}

.page {
	margin: clamp(1rem, 2vw + 0.5rem, 2rem);;
	margin-top: 1rem;
	position: relative;
}

.header-page {
	border-bottom: solid;
	border-width: 0.1rem;
	border-color: var(--border-color);
	color: var(--dark);
	display: flex;
	justify-content: space-between;
}

.header-page > span {
	color: var(--primary-bg-color);
}

.header-page h1 {
	color: var(--tertiary-color); 
	font-size: 1.3rem;
	font-weight: bold;
	font-family: "patuaone";
	 
	font-size: 2rem;
	 
}

.table {
	border-left: 1px solid var(--border-color);
	border-top: 1px solid var(--border-color);

	border-color: var(--border-color);
	display: table;
	width: 100%;
}

.table-header {
	display: table-header-group;
	font-weight: bold;
	background-color: var(--tertiary-bg-color);
	color: var(--dark);
}

.table-header-cell {
	display: table-cell;
	padding: 0.5rem;
	text-align: justify;
	border-bottom: 1px solid var(--border-color);
	border-right: 1px solid var(--border-color);
}

.table-body {
	display: table-row-group;
}

.table-row {
	display: table-row;
}

.body-cell {
	display: table-cell;
	border-bottom: 0.05rem solid var(--border-color);
	border-right: 0.05rem solid var(--border-color);
	padding: 0.5rem;
}

.form {
	margin-top: 1rem;
	margin-bottom: 1rem;
	display: table-caption;
	justify-content: space-between;
}

button {
	background-color: var(--primary-color);
	color: var(--primary-bg-color);
	font-size: 0.8rem;
	border: none;
	border-radius: 0.3rem;
	padding: 0.5rem 1rem;
}

input:not([type="checkbox"]),
select,
textarea {
	padding: 0.3rem;
	border: 0.1rem solid var(--border-color);
	background-color: var(--main-bg-color);
	font-size: 0.8rem;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
}

button:hover {
	cursor: pointer;
}

.section {
	background-color: var(--primary-bg-color);
	border-radius: 1rem;
	display: flex;
	padding: 1rem;
	margin-bottom: 1rem;
}

.section .items {
	display: block;
}

 

h2 {
	color: var(--tertiary-color);
	font-size: 1.3rem;
	font-weight: bold;
	font-family: "patuaone";
	margin-top: 1rem;
	margin-bottom: 1rem;
}

h3 {
	color: var(--tertiary-color); 
	font-size: 1rem;
	font-weight: bold;
	font-family: "patuaone";
}

p {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

ul.item-list {
	list-style-type: none;
}

ul.item-list li {
	 
	border-radius: 1rem;
	padding: 1rem;
	margin-bottom: 1rem;
}

ul.item-list li:hover {
	cursor: pointer;
}

p{
	padding: 1rem;
padding-bottom: 1rem;
background-color: white;
margin-bottom: 1rem;
margin-top: 1rem;
}

.box-theme-one{
	background-color: var(--primary-color);
	color: var(--primary-bg-color);
	border-radius: 0.5rem; 
}

.box-theme-two{
	background-color: var(--primary-bg-color);
	color: var(--tertiary-color);
	border-radius: 0.5rem; 
}