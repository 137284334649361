.loading-active {
	background-color: black;
	height: 100vh;
	width: 100%;
	position: absolute;
	top: 0px;
	display: flex;
	z-index: 100;
	color: white;
	opacity: 0.5;
	justify-content: center;
	align-items: center;
}

.loading-inactive {
	display: none;
}

.loading-gif {
	width: 7rem;
	height: 7rem;
	 
}

.loading-active:hover {
	cursor: auto;
}
