nav {
	background-color: var(--primary-bg-color);
	color: var(--secundary);
	font-weight: bold;
}

nav > div {
	 
}

nav > div:first-child {
	background-color: var(--primary-bg-color);
	height: 5rem;
}



nav > div > div:first-child {
	padding-top: 2rem;
}

nav > div > div {
	padding: 1rem;
	 
	cursor: pointer;
	font-size: 0.8rem;
	text-align: center;
	margin-left: 0.8rem;
}

.menu-icon {
	font-size: 1.5rem;
	padding-bottom: 0.5rem;
}
._active {
	background-color: var(--main-bg-color);
	 
}
