.logo {
	margin-left: 2rem;
	margin-right: 2rem;
	color: var(--tertiary-color);
}

.logo:hover {
	cursor: pointer;
}

.logo > h1 {
	font-size: 3rem;
	font-family: "nunito";
	font-weight: bold;
	font-size: clamp(1rem, 2vw + 1rem, 3rem); 
}
 

.logo > h1 > span:last-child {
	color: var(--dark);
	font-family: "nunito";
	font-weight: bolder;
}