:root {
	--primary-color: #7B46A5;
	--secundary-color: #5E2FC4;
	--tertiary-color: #1C1C1C; 
	--fourth-color: #AF7AD7;
	

 
	--primary-bg-color: #FFFFFF;
	--second-bg-color: #F3F4F6; 
	

	--primary: #3c6e71;
	--secundary: #2F3B49;
	--tertiary: #7B91A8;
	--border-color:#A9ADB0;

	--success: green;
	--alert: #b94032;
	--light: #CECED0;
	--superlight: #FFFFFF;
	--dark: #28394D;
	 
	 
	--op1: #161616;
	--op2: #C94D65;
	--op3: #E7C049;
	--op4: #92B35A;
	--op5: #1F6764;
	
	--op6: #E0BC7A;
	--op7: #BE6352;

	--color1:  #d4cc86 ;
--color2 : #f5f2e7 ;
--color3  :#3c540c ;
--color4  :#7a7e8b ;
--color5  :#d6d0af ;


}