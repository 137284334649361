.home-page {
	display: flex;
}

.groups-column {
	 
}

.events-column {
	margin-left: 1rem;
	margin-right: 1rem;
}
